<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">עדכן קהילה</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="communities" label="קהילה" :item-text="'name'" :item-value="'_id'" return-object
                                    outlined dense v-model="itemEdited.community_id" required
                                    :rules="[(v) => !!v || 'שדה חובה']"></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text @click="updateCommunity()">
                                עדכן
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import SharedFunctions from "@/shared/SharedFunctions";

export default {
    props: {
        itemToEdit: Object,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        overlay: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            community_id: "",
        },
        communities: []
    }),
    methods: {
        async updateCommunity() {
            const myJSON = JSON.stringify({
                community_id: this.itemEdited.community_id._id, customerId: this.itemToEdit._id,
            });
            this.overlay = true;
            let token = localStorage.getItem("token");
            try {
                let api = process.env.VUE_APP_BASE_URL + "/customers/edit_community";
                const res = await fetch(api, ApiServices.requestOptions("PUT", myJSON, token));
                this.progresoverlaysShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("Error: " + jsonObject, "red");
                } else if (res.status === 200) {
                    this.$emit("itemEdited", this.itemEdited, "הקהילה עודכנה בהצלחה", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.overlay = false;
                this.showSnackBar("Error saved edited community: " + error, "red");

            }
        },
        async getCommunities() {
            this.overlay = true;
            this.communities = await SharedFunctions.getCommunities();
            this.overlay = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        console.log(this.itemEdited);
        
        this.getCommunities();

    },
}
</script>
<style></style>