<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.card_id" type="number" hide-spin-buttons label="ת.ז"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" type="number" hide-spin-buttons label="טלפון"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.email" label="אימייל"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle !== 'ערוך משתמש'">
                                <v-text-field v-model="passObj.password" label="סיסמה" :rules="[
                                    v => !!v || 'שדה חובה',
                                    v => (!v || /^[A-Za-z0-9]{9,}$/.test(v) || 'סיסמה חייבת להכיל לפחות 9 תווים באנגלית ובמספרים')
                                ]"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle !== 'ערוך משתמש'">
                                <v-text-field v-model="passObj.repeat_password" label="אימות סיסמה"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.shop_name" label="שם החנות"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.shop_address" label="מיקום החנות"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="rolesOptions" :item-text="'hebrewName'" :item-value="'name'" hide-details
                                    v-model="itemEdited.role" outlined dense label="הרשאות" :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'" hide-details
                                    v-model="itemEdited.status" outlined dense label="סטטוס">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="itemEdited.role === 'seller'">
                                <v-text-field v-model="itemEdited.setup_fee" type="number" hide-spin-buttons
                                    label="דמי הקמה"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="itemEdited.role === 'agent'">
                                <v-file-input label="לוגו סוכן" outlined dense style="width: 300px; max-width: 100%;"
                                    v-model="logoAttached" accept="image/*" prepend-icon="mdi-camera"
                                    placeholder="לא נבחרו קבצים" show-size color="deep-purple accent-4"></v-file-input>
                            </v-col>

                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך משתמש' ? saveEditedUser() : saveNewUser()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        customerZeut: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            card_id: "",
            status: true,
            name: "",
            phone: "",
            shop_name: "",
            shop_address: "",
            setup_fee: 0,
            logo: "",
        },
        logoAttached: null,
        passObj: {
            password: "",
            repeat_password: "",
        },
        status: [{ hebrewName: "פעיל", name: true }, { hebrewName: "לא פעיל", name: false }],
    }),

    methods: {
        async saveNewUser() {

            if (this.$refs.form.validate()) {

                if (this.passObj.password !== this.passObj.repeat_password) {
                    return this.showSnackBar("נא לאמת את הסיסמה", "red");
                }

                const isValidCardId = this.is_israeli_id_number(this.itemEdited.card_id);
                if (!isValidCardId) {
                    return this.showSnackBar("תעודת הזהות אינה תקינה", "red");
                }

                this.progressShow = true;
                let token = localStorage.getItem("token");
                // Prepare FormData
                let formData = new FormData();
                formData.append("logo", this.logoAttached); // Assuming `imageFile` is the image to be uploaded
                formData.append("user", JSON.stringify(this.itemEdited)); // Append each detail from `otherDetails`
                formData.append("passObj", JSON.stringify(this.passObj)); // Append each detail from `otherDetails`
                // Adjust requestOptions for FormData and include the token
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Adjust as needed
                    },
                    body: formData,
                };

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/users/create_user";
                    const res = await fetch(api, requestOptions);
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("שגיאה בהוספת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("userAdded", this.itemEdited, "המשתמש נוצר בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved user: " + error, "red");
                }
            }

        },
        async saveEditedUser() {

            console.log(1111111);
            if (this.$refs.form.validate()) {

                const isValidCardId = this.is_israeli_id_number(this.itemEdited.card_id);
                if (!isValidCardId) {
                    return this.showSnackBar("תעודת הזהות אינה תקינה", "red");
                }

                this.progressShow = true;
                let token = localStorage.getItem("token");
                // Prepare FormData
                let formData = new FormData();
                formData.append("logo", this.logoAttached); // Assuming `imageFile` is the image to be uploaded
                formData.append("user", JSON.stringify(this.itemEdited)); // Append each detail from `otherDetails`
                // Adjust requestOptions for FormData and include the token
                let requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Adjust as needed
                    },
                    body: formData,
                };
                console.log(222222);

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/users/edit_user";
                    const res = await fetch(api, requestOptions);
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("שגיאה בעריכת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("userEdited", this.itemEdited, "המשתמש עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit user: " + error, "red");

                }
            }
        },
        is_israeli_id_number(id) {
            id = String(id).trim();
            if (id.length > 9 || isNaN(id)) return false;
            id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
            return Array.from(id, Number).reduce((counter, digit, i) => {
                const step = digit * ((i % 2) + 1);
                return counter + (step > 9 ? step - 9 : step);
            }) % 10 === 0;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        rolesOptions() {
            return Auth.getUserRole() === "admin" ? [{ hebrewName: "מנהל", name: "admin" }, { hebrewName: "סוכן", name: "agent" }, { hebrewName: "משווק", name: "seller" }]
            : [{ hebrewName: "משווק", name: "seller" }]
        }
    },
    mounted() {
        if (this.formTitle === "ערוך משתמש") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            this.itemEdited.status = this.itemEdited.status === "פעיל" ? true : false;
            this.itemEdited.role = this.itemEdited.role === "מנהל" ? "admin" : this.itemEdited.role === "סוכן" ? "agent" : "seller";
        }
    },
}
</script>
<style></style>
  