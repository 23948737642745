<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="filteredPlans" :item-text="'name'" return-object hide-details
                                    v-model="planChoosed" outlined dense label="בחר חבילה חדשה" required
                                    :rules="[v => !!v || 'שדה חובה']">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text @click="updatePlan()">
                                עדכן
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import SharedFunctions from '@/shared/SharedFunctions';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            manui_number: "",
            status: "",
        },
        planChoosed: {},
        plans: [],
        manuiPacks: []
    }),
    methods: {
        async updatePlan() {
            if (this.$refs.form.validate()) {
                const myJSON = JSON.stringify({
                    packNumToAdd: this.planChoosed.pack_id, manuiNumber: this.itemEdited.manui_number, from: "website"
                });
                this.progressShow = true;
                let token = localStorage.getItem("token");
                try {
                    let api = process.env.VUE_APP_BASE_URL + "/manuim/edit_manui_plan";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", myJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.itemEdited.plan_id = this.planChoosed;
                        this.$emit("itemEdited", this.itemEdited, "החבילה עודכנה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited plan: " + error, "red");
                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getPlans() {
            this.plans = await SharedFunctions.getPlans();
            this.manuiPacks = await SharedFunctions.getManuiPacks(this.itemEdited.manui_number);
        },
    },
    computed: {
        filteredPlans() {
            return this.plans.filter((plan) => plan.operating_company === this.itemEdited.plan_id.operating_company);
        },

        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.getPlans();
        this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        this.planChoosed = this.itemEdited.plan_id;
    },
}
</script>
<style></style>
  